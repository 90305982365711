import React, { useEffect, useState } from "react";
import { Button, Modal, Form, Input, DatePicker, Select, InputNumber, message, notification } from "antd";
import dayjs from "dayjs";
import "dayjs/locale/tr"; 
import locale from "antd/es/date-picker/locale/tr_TR"; 
import MembersTable from "./Components/PersonsTable/view";
import { getAllMembers, createMember, createUser, getAllUsers, isUser, logoutUser } from "./Api/calls";
import useStore from "./State Managements/store";
import UsersTable from "./Components/UsersTable/view";
import { useNavigate } from "react-router-dom";
import { LogoutOutlined } from "@ant-design/icons";
const { Option } = Select;

export default function Admin() {

  const [isModalVisible, setIsModalVisible] = useState(false);
  const {allMembers, setAllMembers, identity_no, password, setIdentity_no, setPassword, setAllUsers, setSign, signInfo, setSignInfo} = useStore();
  const navigate = useNavigate();

  const checkUserAuth = async () => {
    try {
      const response = await isUser();
      setSignInfo(response);

      setSign(true);
    } catch (error) {
      setSign(false);
     
    }
  };

  useEffect(() => {
    checkUserAuth();
  }, []);
 

  useEffect(() => {
    const fetchMembers = async () => {
      try {
        const data = await getAllMembers();
        setAllMembers(data);
      } catch (error) {
        notification.error({
          message: 'Error',
          description: error.message,
        });
      } 
    };

    fetchMembers();
  }, []);


  const [form] = Form.useForm();
  const [formUserSign] = Form.useForm();

  const showModal = () => {
    setIsModalVisible(true);
  };

 

 
  const handleLogout = async () => {
    try {
      const response= await logoutUser();
   
      setSign(false);
      navigate('/login', { replace: true });
      message.success('Başarıyla çıkış yapıldı');
      setSignInfo(null);
    } catch (error) {
      message.error('Çıkış yaparken bir hata oluştu');
    }
  };

  const handleCancel = () => {
    form.resetFields(); // Reset form fields
    setIsModalVisible(false);
  };

  const onFinish = async (values) => {
    
    try {
      await createMember(values);
      message.success("Üye başarıyla kaydedildi!");
      setAllMembers(await getAllMembers());
      form.resetFields();
    setIsModalVisible(false);
    } catch (error) {
      console.error("Üye kaydı başarısız  :", error.hata);
      message.error("Üye kaydı başarısız !", error.hata);
    }
  };

  const onFinishUserSign = async (values) => {
    try {
      await createUser(values);
      message.success("Üye başarıyla kaydedildi!");
      setAllUsers(await getAllUsers());
      formUserSign.resetFields();
    } catch (res) {
      console.error("Üye kaydı başarısız  :", res);
      message.error("Üye kaydı başarısız !", res.hata);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    message.error("Kayıt başarısız!");
  };

  const handlePhoneInput = (e) => {
    const value = e.target.value.replace(/\D/g, '');
    const formattedValue = value.replace(/^(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})$/, (_, g1, g2, g3, g4, g5) => {
      let result = '';
      if (g1) result += g1;
      if (g2) result += ` (${g2}`;
      if (g3) result += `) ${g3}`;
      if (g4) result += ` ${g4}`;
      if (g5) result += ` ${g5}`;
      return result;
    });
    form.setFieldsValue({ phone: formattedValue });
  };

 

    return (
      <div className="admin-divi h-screen w-screen flex flex-col items-center justify-start mt-[50px] gap-10">
         <div className="w-full flex flex-row justify-end items-center gap-5 lg:pl-[200px] lg:pr-[200px] pl-10 pr-10 ">
          <span className="text-[20px]   ">{`${signInfo.name}, ${signInfo.role}`}</span>
        <LogoutOutlined className="text-[35px] bg-red-100 text-red-500 px-8 py-4 rounded" onClick={handleLogout} />
        </div>
        <h2 className="font-semibold text-[30px] text-center mb-8">Admin Panel</h2>
        <MembersTable allMembers={allMembers}/>
        <Button className="px-8 py-2 mb-[100px]" type="primary" onClick={showModal}>
         Yeni Üye Ekle
        </Button>
        {signInfo.role==="Yönetici" ? (
          <>
              <div className="w-full h-fit font-semibold text-[30px] text-center">Yeni Kullanıcı Ekle</div>
          <Form
          form={formUserSign}
          className="w-full h-fit flex flex-col justify-center items-center lg:w-[600px] px-10"
              name="userSign"
              initialValues={{ identity_no:null, password:null, role:"Standart" }}
              onFinish={onFinishUserSign}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item
              className="w-full"
                name="identity_no"
                rules={[
                  { required: true, message: "Lütfen TC kimlik numaranızı giriniz!" },
                  { min: 11, message: "TC kimlik numarası 11 karakter olmalıdır!" },
                  { max: 11, message: "TC kimlik numarası 11 karakter olabilir!" },
                  { pattern: /^[0-9]+$/, message: "TC kimlik numarası sadece rakamlardan oluşmalıdır!" }
                ]}
              >
                <Input
                  placeholder="TC Kimlik No"
                  value={identity_no}
    
                />
              </Form.Item>
    
              <Form.Item
              className="w-full"
                name="name_surname"
                rules={[
                  { required: true, message: "Lütfen Ad Soyad giriniz!" },
                ]}
              >
                <Input
                  placeholder="Ad Soyad"
                  value={identity_no}
       
                />
              </Form.Item>
    
              <Form.Item
              className="w-full"
                name="password"
                rules={[
                  { required: true, message: "Lütfen şifrenizi giriniz!" },
                  { min: 8, message: "Şifre en az 8 karakter olmalıdır!" },
                  { max: 50, message: "Şifre en fazla 50 karakter olabilir!" },
                  { pattern: /[A-Z]/, message: "Şifrede en az bir büyük harf olmalıdır!" },
                  { pattern: /[0-9]/, message: "Şifrede en az bir rakam olmalıdır!" },
                  { pattern: /[!@#$%^&*(),.?":{}|<>]/, message: "Şifrede en az bir noktalama işareti olmalıdır!" }
                ]}
              >
                <Input.Password
                  placeholder="Şifre"
                  value={password}
      
                />
              </Form.Item>
    
              <Form.Item
              className="w-full"
                name="role"
                rules={[{ required: true, message: "Lütfen kategoriyi seçiniz!" }]}
              >
                <Select>
                  <Option value="Standart">Standart</Option>
                  <Option value="Yönetici">Yönetici</Option>
                </Select>
              </Form.Item>
    
              <Form.Item>
                <Button className="px-8 py-2"  type="primary" htmlType="submit">
                  Yeni Kullanıcı Ekle
                </Button>
              </Form.Item>
            </Form>
            <UsersTable/>
            </>
        ):<></>}
    
        
        <Modal
          title="Üye Kayıt Formu"
          open={isModalVisible}
          onCancel={handleCancel}
          footer={null}
          
        >
          <Form
            form={form}
            name="register"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            initialValues={{
              membershipDate: dayjs(),
              lastVisitDate: dayjs(),
              lastMagazineVisitDate: dayjs(),
              lastPresidentVisitDate: dayjs(),
              lastEditor:signInfo.name,
              lastMagazine:'temmuz'
            }}
          >
            <Form.Item
              name="firstName"
              label="Adı"
              rules={[{ required: true, message: "Lütfen adınızı giriniz!" }]}
              
              
            >
              <Input />
            </Form.Item>
  
            <Form.Item
              name="lastName"
              label="Soyadı"
              rules={[{ required: true, message: "Lütfen soyadınızı giriniz!" }]}
            >
              <Input />
            </Form.Item>
  
            <Form.Item
              name="category"
              label="Kategorisi"
              rules={[{ required: true, message: "Lütfen kategoriyi giriniz!" }]}
            >
              <Input />
            </Form.Item>
  
            <Form.Item
              name="phone"
              label="Telefonu"
              rules={[
                { required: true, message: "Lütfen telefon numaranızı giriniz!" },
                {
                  pattern: /^0 \(\d{3}\) \d{3} \d{2} \d{2}$/,
                  message: "Geçerli bir telefon numarası giriniz!",
                },
              ]}
            >
              <Input onChange={handlePhoneInput} />
            </Form.Item>
  
            <Form.Item
              name="address"
              label="Adresi"
              rules={[{ required: true, message: "Lütfen adresinizi giriniz!" }]}
            >
              <Input />
            </Form.Item>
  
            <Form.Item
              name="membershipDate"
              label="Üyelik Tarihi"
              rules={[{ required: true, message: "Lütfen üyelik tarihini seçiniz!" }]}
            >
              <DatePicker locale={locale} format="YYYY-MM-DD" />
            </Form.Item>
  
            <Form.Item
              name="lastVisitDate"
              label="Son Ziyaret Tarihi"
              rules={[{ required: true, message: "Lütfen son ziyaret tarihini seçiniz!" }]}
            >
              <DatePicker locale={locale} format="YYYY-MM-DD" />
            </Form.Item>
  
            <Form.Item
              name="lastMagazineVisitDate"
              label="Son Dergi Ziyareti Tarihi"
              rules={[{ required: true, message: "Lütfen son dergi ziyaret tarihini seçiniz!" }]}
            >
              <DatePicker locale={locale} format="YYYY-MM-DD" />
            </Form.Item>
  
            <Form.Item
              name="lastDonation"
              label="Son Bağış (₺)"
              rules={[{ required: true, message: "Lütfen son bağış miktarını giriniz!" }]}
            >
              <InputNumber min={0} />
            </Form.Item>
  
            <Form.Item
              name="totalDonation"
              label="Toplam Bağış (₺)"
              rules={[{ required: true, message: "Lütfen toplam bağış miktarını giriniz!" }]}
            >
              <InputNumber min={0} />
            </Form.Item>
  
            <Form.Item
              name="lastPresidentVisitDate"
              label="Başkanın Son Ziyaret Tarihi"
              rules={[{ required: true, message: "Lütfen başkanın son ziyaret tarihini seçiniz!" }]}
            >
              <DatePicker locale={locale} format="YYYY-MM-DD" />
            </Form.Item>
            <Form.Item
            name="member_note"
            label="Hakkında Not"
            rules={[{ required: false, message: "Lütfen notunuzu giriniz!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="lastMagazine"
            label="Son Verilen Dergi"
            
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="lastEditor"
            label="Son Düzenleyen"
            rules={[{ required: false, message: "Bu veri zorunludur" }]}
            
          >
            <Input disabled  />
          </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Kaydet
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }


  

